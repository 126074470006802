import { useState } from "react"
import { sitePhrasing } from "../../../Language/languageHandler"
import { updateCart } from "../../../Redux/Shopping/shoppingSlice"
import { useAppDispatch } from "../../../Redux/store"
import { AddressInputFieldGroup } from "../../FormFields/AddressFieldGroup/AddressInputFieldGroup"
import { ArrayFieldInput } from "../../FormFields/ArrayField/ArrayFieldInput"
import CheckboxInputField from "../../FormFields/CheckboxInputField/CheckboxInputField"
import RadioInputField from "../../FormFields/RadioInputField/RadioInputField"
import TextInputField from "../../FormFields/TextInputField/TextInputField"
import { ContactInfoFields } from "./ContactInfoFields"
import { feiEinStatusOptions, yesOrNoValues } from "./PurchaseConstants"
import { WarningMessage } from "../../WarningMessage/WarningMessage"
import ImportantMessage from "../../ImportantMessage/ImportantMessage"

export const FictitiousNameRenewalFields = (props: {
    errors: any,
    touched: any,
    values: any,
    setFieldValue: (field: string, value: any) => void,
    selectedProducts: any,
}) => {
    const {
        values,
        errors,
        touched,
        setFieldValue,
        selectedProducts = {},
    } = props

    const dispatch = useAppDispatch()

    const [tableSearchValue, setTableSearchValue] = useState('')
    const [tableString, setTableString] = useState('')
    const [manualInput, setManualInput] = useState(false)

    const [detailsLoading, setDetailsLoading] = useState(false)

    const updateShoppingCart = (product: string) => {
        dispatch(updateCart(product))
    }

    const asyncSearch = async (documentId: string) => {
        // const documentId = 'GB18000052647'
        //  const documentId = 'G18000052647'

        if (documentId) {
            const {
                detailTable,
                parsed
            } = await fetch(`${sitePhrasing.serverUrl}/fictname-info/${documentId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
            }).then(res => {
                return res.json()
            }).then(data => {
                return data
            }).catch(err => {
                console.log('something went wrong')
                console.log(err)
                return {
                    error: true,
                    errorData: err
                }
            })
            return {
                detailTable,
                parsed
            }
        }
        return {}
    }

    const handleSearch = async () => {
        const searchValue = values.referenceNumber
        if (tableSearchValue === searchValue) { return }
        setDetailsLoading(true)
        setTableSearchValue(searchValue)
        setTableString('')
        setFieldValue('fictitiousNameRenewal.informationStillAccurate', '')
        setFieldValue('fictitiousNameRenewal.isThisYourInformation', '')
        setFieldValue('fictitiousNameRenewal.tradeNameDBA', '')
        setFieldValue('businessName', '')
        setFieldValue('fictitiousNameRenewal.sunbizPayload', {})
        const { detailTable, parsed = {} } = await asyncSearch(searchValue)
        setDetailsLoading(false)
        if (detailTable && parsed && parsed['Fictitious Name']) {
            setTableString(detailTable)
            // extract fictitious name from table
            const fictitiousName = parsed['Fictitious Name']
            setFieldValue('fictitiousNameRenewal.tradeNameDBA', fictitiousName)
            setFieldValue('businessName', fictitiousName)
            setFieldValue('fictitiousNameRenewal.sunbizPayload', parsed)
            setManualInput(false)
            return
        }

        setTableString('error')
        // setFieldValue('fictitiousNameRenewal.informationStillAccurate', 'no')
        setManualInput(false)
    }

    const handleManualMode = () => {
        setManualInput(true)
        setTableString('')
        setTableSearchValue('')
        setFieldValue('fictitiousNameRenewal.tradeNameDBA', '')
        setFieldValue('businessName', '')
        setFieldValue('fictitiousNameRenewal.sunbizPayload', {})
        setFieldValue('fictitiousNameRenewal.isThisYourInformation', 'no')
        setFieldValue('fictitiousNameRenewal.informationStillAccurate', 'no')
    }

    let needsFict = true

    if (values?.fictitiousNameRenewal?.sunbizPayload['Expiration Date']) {
        const [month, day, expirationYear] = values?.fictitiousNameRenewal?.sunbizPayload['Expiration Date'].split('/')
        const currentYear = new Date().getFullYear()
        if (expirationYear && +expirationYear > currentYear) {
            needsFict = false
        }
    }

    return (
        <>
            <div
                className="reference-number-lookup"
                style={{
                    padding: `10px 20px`,
                    border: `4px solid #000`,
                    marginBottom: `20px`,
                }}
            >
                Enter your reference number to auto-fill your business information

                <TextInputField
                    mask={sitePhrasing.referenceMask}
                    fieldName={'referenceNumber'}
                    fieldLabel={'Reference Number'}
                    fieldSubLabel={'(This number can be found on the top right hand corner of your letter)'}
                    errors={errors}
                    touched={touched}
                    required={true}
                    placeholder={'Reference Number'}
                />

                <button
                    onClick={handleSearch}
                    type={'button'}
                    role={'button'}
                    className={`step-btn btn${!values.referenceNumber || errors.referenceNumber ? ' disabled-btn' : ''}`}
                    disabled={!values.referenceNumber || errors.referenceNumber}
                >
                    Search
                </button>
                {detailsLoading && (
                    <div className="loading-spinner">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
                {!tableString && !detailsLoading && errors.businessName && !errors.iAgree && (
                    <div className="error" style={{ color: 'red', marginTop: '10px', marginBottom: '10px' }}>
                        Please enter a valid reference number and click search.
                    </div>
                )}
                {tableString === 'error' && (
                    <div className="error" style={{ color: 'red', marginTop: '10px', marginBottom: '10px' }}>
                        The reference number you have entered does not match any fictitious name currently on record. Please check the number and try again, or
                        &nbsp;
                        <div
                            style={{ display: 'inline-block', color: 'blue', cursor: 'pointer' }}
                            onClick={() => {
                                handleManualMode()
                            }}
                        >
                            click here
                        </div>
                        &nbsp;
                        to enter your information manually.
                    </div>
                )}

            </div>

            {values?.fictitiousNameRenewal?.sunbizPayload['Fictitious Name'] && (
                <div className="fict-name-payload">
                    <h3>Fictitious Name</h3>
                    {values?.fictitiousNameRenewal?.sunbizPayload['Fictitious Name']} <br />

                    <h3>Filing Information</h3>
                    <b>Registration Number:</b> {values?.fictitiousNameRenewal?.sunbizPayload['Registration Number']} <br />
                    <b>Status:</b> {values?.fictitiousNameRenewal?.sunbizPayload['Status']} <br />
                    <b>Filed Date:</b> {values?.fictitiousNameRenewal?.sunbizPayload['Filed Date']} <br />
                    <span style={needsFict ? {} : { color: 'red' }}><b>Expiration Date:</b> {values?.fictitiousNameRenewal?.sunbizPayload['Expiration Date']}</span> <br />
                    <b>Current Owners:</b> {values?.fictitiousNameRenewal?.sunbizPayload['Current Owners']} <br />
                    <b>County:</b> {values?.fictitiousNameRenewal?.sunbizPayload['County']} <br />
                    <b>Total Pages:</b> {values?.fictitiousNameRenewal?.sunbizPayload['Total Pages']} <br />
                    <b>Events Filed:</b> {values?.fictitiousNameRenewal?.sunbizPayload['Events Filed']} <br />
                    <b>FEI/EIN Number:</b> {values?.fictitiousNameRenewal?.sunbizPayload['FEI/EIN Number']} <br />

                    <h3>Mailing Address</h3>
                    {values?.fictitiousNameRenewal?.sunbizPayload['Mailing Address'] && (
                        <>
                            {values?.fictitiousNameRenewal?.sunbizPayload['Mailing Address']['address']} <br />
                            {values?.fictitiousNameRenewal?.sunbizPayload['Mailing Address'] && (
                                <>
                                    {values?.fictitiousNameRenewal?.sunbizPayload['Mailing Address']['address2']} <br />
                                </>
                            )}
                            {values?.fictitiousNameRenewal?.sunbizPayload['Mailing Address']['city']},&nbsp;
                            {values?.fictitiousNameRenewal?.sunbizPayload['Mailing Address']['state']}&nbsp;
                            {values?.fictitiousNameRenewal?.sunbizPayload['Mailing Address']['zip']}
                            <br />
                        </>
                    )}

                    <h3>Owner Information</h3>
                    {values?.fictitiousNameRenewal?.sunbizPayload['Owner Information'] && (
                        <>
                            {values?.fictitiousNameRenewal?.sunbizPayload['Owner Information']['name']} <br />
                            {values?.fictitiousNameRenewal?.sunbizPayload['Owner Information']['address']} <br />
                            {values?.fictitiousNameRenewal?.sunbizPayload['Owner Information']['address2'] && (
                                <>
                                    {values?.fictitiousNameRenewal?.sunbizPayload['Owner Information']['address2']} <br />
                                </>
                            )}
                            {values?.fictitiousNameRenewal?.sunbizPayload['Owner Information']['city']},&nbsp;
                            {values?.fictitiousNameRenewal?.sunbizPayload['Owner Information']['state']}&nbsp;
                            {values?.fictitiousNameRenewal?.sunbizPayload['Owner Information']['zip']}
                            <br />
                            <b>FEI/EIN Number:</b> {values?.fictitiousNameRenewal?.sunbizPayload['Owner FEI/EIN']} <br />
                            <b>Document Number:</b> {values?.fictitiousNameRenewal?.sunbizPayload['Owner Document Number']} <br />
                        </>

                    )}

                </div>
            )}

            {/* {tableString && tableString !== 'error' && values.referenceNumber === tableSearchValue && (
                <div
                    className="reference-number-lookup"
                    style={{
                        padding: `10px 20px`,
                        border: `4px solid #000`,
                        marginBottom: `20px`,
                    }}
                >
                    <div dangerouslySetInnerHTML={{ __html: tableString }} />
                </div>
            )} */}

            {manualInput && (
                <>
                    <TextInputField
                        mask={sitePhrasing.referenceMask}
                        fieldName={'referenceNumber'}
                        fieldLabel={'Reference Number'}
                        fieldSubLabel={'(This number can be found on the top right hand corner of your letter)'}
                        errors={errors}
                        touched={touched}
                        required={true}
                        placeholder={'Reference Number'}
                    />

                    <TextInputField
                        fieldName={'fictitiousNameRenewal.tradeNameDBA'}
                        fieldLabel={'Fictitious Name/DBA(Doing Business As)'}
                        errors={errors}
                        required={true}
                        touched={touched}
                        placeholder={'Fictitious Name/DBA(Doing Business As)'}
                        onChange={(e: any) => {
                            setFieldValue('fictitiousNameRenewal.tradeNameDBA', e.target.value)
                            setFieldValue('businessName', e.target.value)
                        }}
                    />
                </>
            )}

            {!needsFict && (
                <>
                    <WarningMessage
                        message="You do not need to renew your fictitious name at this time."
                    />
                    <br />
                    <div className="big-fict-warning" style={{color: 'red', fontSize: '1.5em', fontWeight: 'bold'}}>
                        If you opened your fictitious name in 2022 and received a letter from us for renewal, please disregard. 
                        YOU DO NOT NEED TO FILE FOR RENEWAL. 
                        These were sent out in error. 
                        We apologize for the confusion and any inconvenience.
                        <br /><br />
                        Please <u>DO NOT</u> submit your payment through our Annual Report page.
                    </div>

                    <style>
                        {`
                            .final-purchase-form-section {
                                display: none;
                            }
                        `}
                    </style>

                </>

            )}

            {needsFict && tableString && tableString !== 'error' && (
                <div
                    className="important-radio-input"
                    style={{
                        padding: `10px 10px 0px 10px`,
                        border: `4px solid #000`,
                        marginBottom: `20px`,
                    }}
                >
                    <RadioInputField
                        fieldName={'fictitiousNameRenewal.isThisYourInformation'}
                        fieldLabel={'Is the Fictitious Name shown above yours?'}
                        errors={errors}
                        required={true}
                        touched={touched}
                        customClass={`full-width`}
                        options={yesOrNoValues}
                    />

                    {values.fictitiousNameRenewal.isThisYourInformation === 'no' && (
                        <div className="error" style={{ color: 'red', marginTop: '10px', marginBottom: '10px' }}>
                            Please check the reference number you entered and try again, or
                            &nbsp;
                            <div
                                style={{ display: 'inline-block', color: 'blue', cursor: 'pointer' }}
                                onClick={() => {
                                    handleManualMode()
                                }}
                            >
                                click here
                            </div>
                            &nbsp;
                            to enter your information manually.
                        </div>
                    )}

                    {values.fictitiousNameRenewal.isThisYourInformation === 'yes' && (
                        <RadioInputField
                            fieldName={'fictitiousNameRenewal.informationStillAccurate'}
                            fieldLabel={'Is the information above still accurate?'}
                            errors={errors}
                            required={true}
                            touched={touched}
                            customClass={`full-width`}
                            options={[
                                { value: 'yes', label: 'Yes, all my information is still the same.' },
                                { value: 'no', label: 'No, I need to update my information' },
                            ]}
                        />
                    )}

                </div>
            )}


            {needsFict && values.fictitiousNameRenewal.informationStillAccurate === 'no' && (
                <>
                    <h3>Select the following choices to make a change</h3>

                    <RadioInputField
                        fieldName={'fictitiousNameRenewal.updateAddEin'}
                        fieldLabel={'Update/Add EIN?'}
                        errors={errors}
                        required={true}
                        touched={touched}
                        options={yesOrNoValues}
                    />
                    {values.fictitiousNameRenewal.updateAddEin === 'yes' && (
                        <TextInputField
                            mask={[/\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                            fieldName={'fictitiousNameRenewal.feiEin'}
                            fieldLabel={'FEI/EIN Number'}
                            errors={errors}
                            touched={touched}
                            required={false}
                            placeholder={'FEI/EIN Number'}
                        />
                    )}

                    <RadioInputField
                        fieldName={'fictitiousNameRenewal.updateCurrentAddress'}
                        fieldLabel={values.fictitiousNameRenewal.deleteCurrentOwner === 'no' ? 'Update current principal owner address?' : 'Update current mailing address?'}
                        errors={errors}
                        required={true}
                        touched={touched}
                        options={yesOrNoValues}
                    />
                    {values.fictitiousNameRenewal.updateCurrentAddress === 'yes' && (
                        <AddressInputFieldGroup
                            fieldName={'fictitiousNameRenewal.updatedCurrentAddress'}
                            errors={errors}
                            touched={touched}
                        />
                    )}

                    <RadioInputField
                        fieldName={'fictitiousNameRenewal.changeOwner'}
                        fieldLabel={'Change Owner?'}
                        errors={errors}
                        required={true}
                        touched={touched}
                        options={yesOrNoValues}
                        onChange={(e: any) => {
                            setFieldValue('fictitiousNameRenewal.changeOwner', e.target.value)
                            if (e.target.value === 'yes' && !selectedProducts['legalNoticeAdvertisement']) {
                                updateShoppingCart('legalNoticeAdvertisement')
                            } else if (e.target.value === 'no' && selectedProducts['legalNoticeAdvertisement']) {
                                updateShoppingCart('legalNoticeAdvertisement')
                            }
                        }}
                    />

                    {values.fictitiousNameRenewal.changeOwner === 'yes' && (
                        <>
                            <ImportantMessage>
                                <b>IMPORTANT NOTICE:</b> Adding or deleting an owner(s) from the official record
                                requires you to publish these changes in an approved newspaper in the county where
                                your principal place of business is located (legal notice advertisement).&nbsp;
                                <b>
                                    <u>
                                        This costs an additional $175 and has been added to your total cost.
                                    </u>
                                </b>
                            </ImportantMessage>

                            <RadioInputField
                                fieldName={'fictitiousNameRenewal.deleteCurrentOwner'}
                                fieldLabel={'Delete Current Owner?'}
                                errors={errors}
                                required={true}
                                touched={touched}
                                options={yesOrNoValues}
                                onChange={(e: any) => {
                                    setFieldValue('fictitiousNameRenewal.deleteCurrentOwner', e.target.value)
                                    if (e.target.value === 'no') {
                                        setFieldValue('fictitiousNameRenewal.additionalOwners', '')
                                        setFieldValue('fictitiousNameRenewal.newOwners', [])
                                    } else if (e.target.value === 'yes') {
                                        setFieldValue('fictitiousNameRenewal.additionalOwners', '')
                                        setFieldValue('fictitiousNameRenewal.newOwners', [blankNewOwnerFn()])
                                    }
                                }}
                            />

                            {values.fictitiousNameRenewal.deleteCurrentOwner === 'no' && (
                                <>
                                    <ArrayFieldInput
                                        fieldName={'fictitiousNameRenewal.newOwners'}
                                        values={values.fictitiousNameRenewal.newOwners}
                                        blankEntryFn={blankNewOwnerFn}
                                        fields={newOwnerFields}
                                        touched={touched}
                                        errors={errors}
                                        removeText={'- Click remove additional owner'}
                                        addText={'+ Click to add additional owner'}
                                    />
                                </>
                            )}

                            {values.fictitiousNameRenewal.deleteCurrentOwner === 'yes' && (
                                <>
                                    <ArrayFieldInput
                                        fieldName={'fictitiousNameRenewal.newOwners'}
                                        values={values.fictitiousNameRenewal.newOwners}
                                        blankEntryFn={blankNewOwnerFn}
                                        fields={newOwnerFields}
                                        touched={touched}
                                        errors={errors}
                                        minLength={1}
                                        removeText={'- Click remove additional owner'}
                                        addText={'+ Click to add additional owner'}
                                    />
                                </>
                            )}
                        </>
                    )}

                </>
            )}

            {needsFict && (
                <>
                    {/* DEFAULT CONTACT INFO */}
                    <h3>Contact Information</h3>
                    <ContactInfoFields
                        values={values}
                        touched={touched}
                        errors={errors}
                        setFieldValue={setFieldValue}
                    />

                    <CheckboxInputField
                        fieldName={'fictitiousNameRenewal.fictitiousNameAgreement'}
                        fieldLabel={fictitiousNameAgreement}
                        errors={errors}
                        touched={touched}
                    />

                    <CheckboxInputField
                        fieldName={'fictitiousNameRenewal.tellingTruth'}
                        fieldLabel={tellingTruth}
                        errors={errors}
                        touched={touched}
                    />

                    <CheckboxInputField
                        fieldName={'fictitiousNameRenewal.iAmAuthorized'}
                        fieldLabel={iAmAuthorized}
                        errors={errors}
                        touched={touched}
                    />

                    <TextInputField
                        fieldName={'fictitiousNameRenewal.digitalSignature'}
                        fieldLabel={'Client Agreement & Signature'}
                        customClass={'signature-font'}
                        errors={errors}
                        touched={touched}
                        required={true}
                        placeholder={'Sign your name here'}
                    />
                </>
            )}


        </>
    )
}


const fictitiousNameAgreement = `
With my digital signature, (i) I represent and warrant that all of the information provided above is accurate and complete; (ii) I agree that I have carefully read and agree to be bound by the Terms of Service, Refund Policy, and Privacy Policy (see below) and (iii) I have read and understand that I am hereby authorizing Florida Certified Document Services as a "Third Party Designee" to renew my fictitious name on my behalf using the information I provided. I agree to the terms & conditions and authorize this payment to Florida Certified Document Services. I hereby certify that the information above is correct and that I am authorized to conduct this transaction.
`

const tellingTruth = `
I, the undersigned, being an owner in the above fictitious name, certify that the information indicated on this form is true and accurate. I understand that the electronic signature below shall have the same legal effect as if made under oath. I am aware that false information submitted in a document to the Department of State constitutes a third degree felony as provided for in s. 817.155, F.S.
`

const iAmAuthorized = `
The individual "signing" this document affirms that the facts stated herein are true. This signature must be that of the individual "signing" this document electronically or be made with the full knowledge and permission of the individual, otherwise it constitutes forgery under s. 831.06, F.S.
`

export const blankNewOwnerFn = () => ({
    ownerType: '',

    firstName: '',
    lastName: '',

    businessName: '',
    feiEin: '',
    feiEinStatus: '',

    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
})

export const newOwnerFields: any[] = [
    {
        fieldType: 'radio',
        fieldName: 'ownerType',
        fieldLabel: 'Is the new owner a person or a business?',
        placeholder: 'Owner Type',
        required: true,
        options: [
            { value: 'person', label: 'Person' },
            { value: 'business', label: 'Business' },
        ]
    },
    {
        fieldType: 'text',
        fieldName: 'firstName',
        fieldLabel: 'First Name',
        placeholder: 'Last Name',
        required: true,
        showIf: { ownerType: (v: any) => v === 'person' }
    },
    {
        fieldType: 'text',
        fieldName: 'lastName',
        fieldLabel: 'Last Name',
        placeholder: 'Last Name',
        required: true,
        showIf: { ownerType: (v: any) => v === 'person' }
    },

    {
        fieldType: 'text',
        fieldName: 'businessName',
        fieldLabel: 'Business Name',
        placeholder: 'Business Name',
        required: true,
        showIf: { ownerType: (v: any) => v === 'business' }
    },
    {
        fieldType: 'text',
        fieldName: 'feiEin',
        fieldLabel: 'FEI/EIN Number',
        placeholder: 'FEI/EIN Number',
        extra: {
            mask: [/\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        },
        showIf: { ownerType: (v: any) => v === 'business' }
    },
    {
        fieldType: 'radio',
        fieldName: 'feiEinStatus',
        fieldLabel: 'FEI/EIN Status',
        placeholder: 'FEI/EIN Status',
        required: true,
        options: feiEinStatusOptions,
        extra: { customClass: 'block-radio' },
        showIf: { ownerType: (v: any) => v === 'business' }
    },
    {
        fieldType: 'custom',
        fieldName: 'x',
        fieldLabel: 'x',
        extra: {
            component: () => (
                <div className="field-note">
                    If you have not yet obtained your EIN Federal Tax ID, you may do so now by <a href="/eintaxid/" target="_blank" className="link-text">clicking here</a>.
                </div>
            )
        },
        showIf: { ownerType: (v: any) => v === 'business' }
    },


    {
        fieldType: 'text',
        fieldName: 'address',
        fieldLabel: 'Address',
        placeholder: 'Address',
        required: true,
        showIf: { ownerType: (v: any) => v }
    },
    {
        fieldType: 'text',
        fieldName: 'address2',
        fieldLabel: 'Address 2',
        placeholder: 'Address 2',
        showIf: { ownerType: (v: any) => v }

    },
    {
        fieldType: 'text',
        fieldName: 'city',
        fieldLabel: 'City',
        placeholder: 'City',
        required: true,
        showIf: { ownerType: (v: any) => v }
    },
    {
        fieldType: 'text',
        fieldName: 'state',
        fieldLabel: 'State',
        placeholder: 'State',
        required: true,
        showIf: { ownerType: (v: any) => v }
    },
    {
        fieldType: 'text',
        fieldName: 'zip',
        fieldLabel: 'Zip',
        placeholder: 'Zip',
        required: true,
        showIf: { ownerType: (v: any) => v }
    }
]