import { useSelector } from "react-redux"
import { shippingOptions } from "../../Redux/Shopping/shoppingHelpers"
import { selectShopping } from "../../Redux/Shopping/shoppingSlice"

export default function CartInformation() {

    const {
        selectedProducts,
        shippingMethod,
        discount,
        cartTotal,
        shippingTotal,
    } = useSelector(selectShopping)

    const calculateTotalWithShipping = () => {
        let total = 0
        if (cartTotal) {
            total += cartTotal
        }
        if (discount) {
            total -= discount
        }
        if (shippingTotal) {
            total += shippingTotal
        }
        return (total / 100).toFixed(2)
    }


    return (
        <div className="totals-container">
            <div className="product-info-container total-section">
                <b>Products:</b><br />
                {Object.keys(selectedProducts).length === 0 && (
                    <span>No products selected</span>
                )}
                {Object.keys(selectedProducts).map((x: string, idx: number) => (
                    <div className={`product-entry ${x} ${idx}`} key={`${x}-${idx}`}>
                        {selectedProducts[x].customName ? (
                            <div
                                className="product-name-cart"
                                dangerouslySetInnerHTML={{
                                    __html: `${selectedProducts[x].customName} $${(selectedProducts[x].price / 100).toFixed(2)}`
                                }}
                            />
                        ) : (
                            <div
                                className="product-name-cart"
                            >
                                {selectedProducts[x].name} ${(selectedProducts[x].price / 100).toFixed(2)}
                            </div>
                        )}
                        
                    </div>
                ))}
            </div>

            <div className="cart-total-container total-section">
                <b>Cart Total:</b> ${(cartTotal / 100).toFixed(2)}
            </div>
            {shippingMethod && (
                <div className="shipping-total-container total-section">
                    <b>Shipping:</b> <br /> {shippingOptions[shippingMethod].name} ${(shippingTotal / 100).toFixed(2)}
                </div>
            )}

            {discount > 0 && (
                <div className="discount-container total-section">
                    <b>Discount:</b> -${(discount / 100).toFixed(2)}
                </div>
            )}

            <div className="total-container total-section">
                <b>Current Total:</b> ${calculateTotalWithShipping()}
            </div>
        </div>
    )
}